import React, { useRef, useState, useEffect } from 'react';

import { Link } from 'gatsby';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import SEO from '../components/App/SEO';
import { graphql } from 'gatsby';
import gemAiEmpowering from '../assets/images/introducing-gemai/gemAi-convo-empowering.webp';
import gemAiFuture from '../assets/images/introducing-gemai/gemAi-convo-the-future.webp';
import arrowWhite from '../assets/images/introducing-gemai/arrow-white.svg';
import GemAIConvo from "../assets/images/introducing-gemai/gemai-convos.mp4";
import PlayImg from "../assets/images/play-image.svg";
import VideoPlaceholderImg from "../assets/images/generative-ai/GemAI_Convo_v.png";
import screenshot1 from '../assets/images/introducing-gemai/gemconvo_1.png';
import screenshot2 from '../assets/images/introducing-gemai/gemconvo_2.png';
import screenshot3 from '../assets/images/introducing-gemai/gemconvo_3.png';


import HighlightIcon1 from '../assets/images/introducing-gemai/highlights1.svg';
import HighlightIcon2 from '../assets/images/introducing-gemai/highlights2.svg';
import HighlightIcon3 from '../assets/images/introducing-gemai/highlights3.svg';



const IntroGemAIConvo = ({ data }) => {
    const videoRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);
    const [platform, setPlatform] = useState("");
    
   // Adjusted styles
   const flexContainerStyle = {
    display: 'flex',
    flexDirection: 'row', // default direction
    justifyContent: 'center', // Center buttons on large screens
    flexWrap: 'wrap', // Allow wrapping for responsiveness
    marginTop: '24px'
};

const buttonStyle = {
    padding: "12px 20px", // Adjust padding for a better look
    margin: '5px', // Uniform margin for spacing
    flex: '0 1 auto', // Flex grow is 0, flex shrink is 1 and flex-basis is auto
    minWidth: '150px', // Minimum width for buttons
    textAlign: 'center' // Center text in buttons

};

const containerStyle = {
    maxWidth: '1200px', // Adjust as per your layout's width
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 15px',
    paddingBottom: '120px' // Increase bottom padding to create a gap
};


    const handleVideo = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsChecked(true);
        } else {
            videoRef.current.pause();
            setIsChecked(false);
        }
    };

    return (
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO
                postTitle='Introducing GemAI Convos | BlueMail App'
                postDescription='Group AI in Email: Transforming Team Communication with GemAI Convos'
                postImage='/img/OG/gemAi-convo-the-future.png'
                postURL='intro-gemai-convos'
                postSEO
            />

            {/* Inline CSS with Media Queries */}
            <style>
                {`

                    .controls {
                        position: relative;
                        text-align: center;
                        width: 100%; /* Ensure controls occupy full width */
                    }

                
                     .row {
                         margin-top: 30px; /* Consistent vertical gaps */
                     }
     
                     .controls video, .controls img {
                         max-width: 100%;
                         
                         height: auto;
                     }
     
                     .video-play-image {
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                         cursor: pointer;
                         z-index: 10;
                     }

                     .commonButton {
                        background-color: #1F6BF1;
                        color: white;
                        margin: 10px;
                        padding: 12px 24px;
                        font-size: 24px;
                        border-radius: 6px;
                        text-transform: none;
                        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                    }
                    
                    .commonButton:hover {
                        background-color: rgba(74, 111, 241, 0.8); /* Semi-transparent lighter blue */
                        transform: scale(1.05);
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                    }
                    
                    
                    
                    

                     .highlight {
                        display: flex;
                        align-items: center; /* Aligns icon with text vertically */
                        margin-bottom: 10px; /* Space between each highlight */
                      }

                      .highlight_icon {
                        width: 30px; !important;
                        height: 30px;  !important;
                        margin-right: 20px !important;
                        
                    }
                      
                      .icon-placeholder {
                        width: 30px; /* Width of your icon */
                        height: 30px; /* Height of your icon */
                        margin-right: 10px; /* Space between icon and text */
                        background-color: #ccc; /* Placeholder color, replace with your icon */
                        display: inline-block;
                      }
                      
                      @media (min-width: 992px) { /* Adjusts for large screens */
                          .controls video {
                              width: 80%; /* Reducing the width to 60% of its container */
                              margin: 0 auto; /* Centering the video */
                          }
                      }
                    
                     @media (max-width: 768px) {
                         .container {
                             padding: 15px;
                         }
     
                         .col-lg-12, .col-md-12, .col-lg-8, .col-md-8, .col-lg-4, .col-md-4 {
                             flex: 0 0 100%;
                             max-width: 100%;
                         }
                         
                         // Adjusted styles for mobile
                         .pt-120, .pb-50 {
                             padding-top: 60px;
                             padding-bottom: 50px;
                         }
                     }

                     
                           
                    
                     `}
                 </style>
       
                 <div className="container pt-120 pb-100 introducingGemAI">
    <div className='row'>
        <div className='col-lg-12 col-md-12 col-xs-12'>
            <h1>GemAI Convos - Group AI in Email</h1>
        </div>
    </div>
    <div className='row'>
        <div className='col-lg-12 col-md-12 col-xs-12'>
            <img src={gemAiFuture} alt='GemAI Future'/>
        </div>
    </div>
    <div className='row'>
        <div className='col-lg-12 col-md-12 col-xs-12'>
            <div className='takeaways'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-xs-12'>
                        <h3>Highlights</h3>
                        <div className='highlight'>
                            <img src={HighlightIcon1} alt="Highlight Icon 1" className="highlight_icon" />
                            <p>Integrate the intelligence of AI directly into your group's email communications with GemAI Convos.</p>                 
                            </div>
                        <div className='highlight'>
                            <img src={HighlightIcon2} alt="Highlight Icon 2" className="highlight_icon" />
                            <p>Enable instant AI responses to prompts within your email environment, enhancing team collaboration.</p>
                        </div>
                        <div className='highlight'>
                            <img src={HighlightIcon3} alt="Highlight Icon 3" className="highlight_icon" />
                            <p>Streamline your team's workflow with seamlessly integrated AI-driven insights, enhancing efficiency and productivity in email discussions.</p>
                        </div>
                    </div>
                </div>                          
            </div>
        </div>
    </div>
    <div className="row pt-120">
        <div className="col-lg-6 col-md-6 col-xs-12 controls">
            <video ref={videoRef} poster={VideoPlaceholderImg} loop muted playsInline>
                <source src={GemAIConvo} type="video/mp4" alt="GemAI Convo" />
            </video>
            {!isChecked && (
                <img onClick={handleVideo} className="video-play-image" src={PlayImg} alt="play button"/>
            )}
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12">
            <h3>Enhanced Collaboration with AI-Enabled Group Emails</h3>
            <p className="m-left">
            GemAI Convos is bringing the power of group of AI into everyday conversations. This is nore than smarter email. It's about making group discussions more dynamic and insightful. Imagine having the intelligence of ChatGPT and Gemini at your fingertips during brainstorming sessions, project management, and when solving complex problems together. GemAI Convos does more than just add AI insights to your chats – it's like having an intelligent assistant in your inbox, offering suggestions and new perspectives. It's about making every team interaction more productive and informed. With GemAI Convos, you're not just sending emails. You're engaging in a collaborative experience that harnesses the latest AI technology to take group communication to a whole new level. </p>        </div>
    </div>
    <div className='row'>
        <div className='col-lg-12 col-md-12 col-xs-12 pt-120'>
            <img src={gemAiEmpowering} alt='GemAi Empowering'/>
        </div>
    </div>
    <div className='row'>
        <div className='col-lg-12 col-md-12 col-xs-12'>
            <h2>Discover GemAI Convos</h2>
            <p><strong>AI Collaboration, Reimagined:</strong> Welcome to a pioneering approach to AI group messaging in email. Dive into lively discussions with your team and AI, all seamlessly integrated within a single, cohesive environment.</p>
            <p><strong>Enhanced Teamwork Through AI:</strong> Engage in email dialogues where both your team and GemAI actively participate, transforming every conversation. It's more than interaction, it's about elevating your team's collaboration with intelligent AI insights.</p>
            <p><strong>Seamless Integration:</strong> Enjoy the convenience of having all AI-enhanced communications neatly organized in your inbox, readily available for reference, review, or to pick up right where you left off, ensuring smooth continuity and easy access.</p>
        </div>
    </div>

</div>


<div className="container pb-10 introducingGemAI">
    <div className='row d-flex align-items-start'>         
        {/* Column for Screenshots */}
        <div className='col-lg-7 col-md-7 col-xs-12'>
            <div className='row align-self-start'> {/* Apply align-self-start here */}
                <div className='col-md-4 col-xs-12'>
                    <img src={screenshot1} alt='BlueMail GemAI Convos' style={{ width: '100%', height: 'auto' }} />
                </div>
                <div className='col-md-4 col-xs-12'>
                    <img src={screenshot2} alt='BlueMail GemAI Convos' style={{ width: '100%', height: 'auto' }} />
                </div>
                <div className='col-md-4 col-xs-12'>
                    <img src={screenshot3} alt='BlueMail GemAI Convos' style={{ width: '100%', height: 'auto' }} />
                </div>
            </div>
        </div>

       {/* Column for Text */}
       <div className='col-lg-5 col-md-5 col-xs-12' style={{ marginTop: '50px' }}>
    <h4><strong>Embrace the Future with GemAI!</strong></h4>
                <p>Welcome to the innovative world of BlueMail GemAI Convos. This journey is not only about exploring our cool new feature—it's an invitation to enhance it with your unique contributions.</p>
                <p>Have you ever dreamed of having an AI companion in your email conversations? BlueMail's GemAI Convos turns that dream into reality! Offering more than a simple feature, it acts as your AI collaborator in group emails, enriching your discussions with unparalleled insights and support.</p>
                <p>Stay ahead with the GemAI adventure—visit our <Link style={{color: '#1F6BF1'}} to='/blog'>blog</Link> for the latest updates and insightful tips on leveraging AI to transform your email conversations.</p>
        </div>

    </div>
</div>





<Footer />
</Layout>
     
     );
    };

export default IntroGemAIConvo;

export const query = graphql`
query IntroGemAIConvoPageQuery {
    site {
        siteMetadata {
            title
            env
        }
    }
    
}
`
